import "core-js/modules/es.string.pad-start.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.array.slice.js";
// 时间格式转换
export function setNowFormatDate(value, type) {
  var d = value;
  var y = d.getFullYear(); // 年份

  var m = (d.getMonth() + 1).toString().padStart(2, '0'); // 月份

  var r = d.getDate().toString().padStart(2, '0'); // 日子

  var h = d.getHours().toString().padStart(2, '0'); // 小时

  var mm = d.getMinutes().toString().padStart(2, '0'); // 分钟

  var ss = d.getSeconds().toString().padStart(2, '0'); // 秒

  var _symbol = '-';
  var date = "".concat(y).concat(_symbol).concat(m).concat(_symbol).concat(r, "  ").concat(h, ":").concat(mm, ":").concat(ss);

  switch (type) {
    case 'year':
      date = "".concat(y); // es6 字符串模板

      break;

    case 'month':
      date = "".concat(y).concat(_symbol).concat(m); // es6 字符串模板

      break;

    case 'day':
      date = "".concat(y).concat(_symbol).concat(m).concat(_symbol).concat(r); // es6 字符串模板

      break;

    case 'hour':
      date = "".concat(y).concat(_symbol).concat(m).concat(_symbol).concat(r, " ").concat(h); // es6 字符串模板

      break;

    case 'minutes':
      date = "".concat(y).concat(_symbol).concat(m).concat(_symbol).concat(r, " ").concat(h, ":").concat(mm); // es6 字符串模板

      break;

    case 'second':
      date = "".concat(y).concat(_symbol).concat(m).concat(_symbol).concat(r, " ").concat(h, ":").concat(mm, ":").concat(ss); // es6 字符串模板

      break;

    case 'onYear':
      date = "".concat(m).concat(_symbol).concat(r, " ").concat(h, ":").concat(mm); // es6 字符串模板

      break;

    case 'chinese_minutes':
      date = "".concat(y, "\u5E74").concat(m, "\u6708").concat(r, "\u65E5").concat(h, "\u65F6").concat(mm, "\u5206"); // es6 字符串模板

      break;

    default:
      date = "".concat(y).concat(_symbol).concat(m).concat(_symbol).concat(r, " ").concat(h, ":").concat(mm, ":").concat(ss);
    // es6 字符串模板
  }

  return date;
} // 获取链接参数

export function getURLParam(name) {
  var url = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : window.location.href;
  var result = url.match(new RegExp('(\\?|&)' + name + '(\\[\\])?=([^&#]*)'));
  return result ? result[3] : '';
}
/**
 * 防抖函数 https://blog.csdn.net/weixin_44571638/article/details/118419042
 * vue this指向问题  https://zhuanlan.zhihu.com/p/128055659
 * @param {*} func 函数名
 * @param {*} wait 等待时间
 * @param {*} immediate true-立即执行 false-非立即执行
 * @returns
 */

export function boDebounce(funcName, wait, immediate) {
  var timeout;
  return function () {
    var _this = this;

    var context = this;
    var args = Array.prototype.slice.call(arguments);
    if (timeout) clearTimeout(timeout);

    if (immediate) {
      var callNow = !timeout;
      timeout = setTimeout(function () {
        timeout = null;
      }, wait);
      if (callNow) this[funcName].apply(context, args);
    } else {
      timeout = setTimeout(function () {
        _this[funcName].apply(context, args);
      }, wait);
    }
  };
}